<template>
  <div class="container-form-login">
    <div>
      <v-btn
        @click="$router.push({ name: 'Signin' })"
        text
      >
        <v-icon
          class="icon-return"
          size="15px"
        >
          mdi-arrow-left
        </v-icon>
        <span class="text-return mon-regular">{{ texts.signinCustomer.buttonReturn }}</span>
      </v-btn>
    </div>
    <div class="content-login">
      <div class="center-horizontall mt-9">
        <div
          @click="home"
          class="content-image"
        >
          <img
            src="@/assets/logos/logo-bg-white.svg"
            alt="proplat-image.png"
          >
        </div>
      </div>
      <p class="text-title mon-bold mt-5">{{ texts.signinCustomer.textTitle }}</p>
      <div class="pa-9 remove-pa-9">
        <v-text-field
          v-model="name"
          type="text"
          :label="texts.signinCustomer.name.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinCustomer.name.placeholder"
          persistent-placeholder
          maxLength="40"
        >
        </v-text-field>
        <v-text-field
          v-model="lastname"
          type="text"
          :label="texts.signinCustomer.lastname.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinCustomer.lastname.placeholder"
          persistent-placeholder
          maxLength="40"
        >
        </v-text-field>
        <v-text-field
          v-model="email"
          type="text"
          :label="texts.signinCustomer.email.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinCustomer.email.placeholder"
          persistent-placeholder
          maxLength="85"
        >
        </v-text-field>
        <v-text-field
          v-model="phone"
          type="text"
          :label="texts.signinCustomer.phone.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinCustomer.phone.placeholder"
          persistent-placeholder
          maxLength="13"
          @keypress="soloNumbers"
        >
        </v-text-field>
        <v-text-field
          v-model="enterpriseName"
          type="text"
          :label="texts.signinCustomer.enterpriseName.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinCustomer.enterpriseName.placeholder"
          persistent-placeholder
          maxLength="60"
        >
        </v-text-field>
        <div class="display-flex align-items-center">
          <!-- country -->
          <v-select
            v-model="country"
            :items="itemsCountries"
            item-text="sName"
            item-value="sCountryId"
            :label="texts.signinCustomer.country.label"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-1"
            style="width: 50%"
            :placeholder="texts.signinCustomer.country.placeholder"
            @input="selecState"
          >
          </v-select>
          <!-- state -->
          <v-select
            v-model="userState"
            :items="itemsStates"
            item-text="sName"
            item-value="sStateId"
            :label="texts.signinCustomer.state.label"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular ml-1"
            style="width: 50%"
            :placeholder="texts.signinCustomer.state.placeholder"
            @input="selecState"
          >
          </v-select>
        </div>
        <v-text-field
          v-model="password"
          :label="texts.signinCustomer.password.label"
          :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (bShowPassword = !bShowPassword)"
          :type="bShowPassword ? 'text' : 'password'"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.signinCustomer.password.placeholder"
          persistent-placeholder
        >
        </v-text-field>
        <v-text-field
          v-model="confirmPassword"
          :label="texts.signinCustomer.confirmPassword.label"
          :append-icon="bShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (bShowConfirmPassword = !bShowConfirmPassword)"
          :type="bShowConfirmPassword ? 'text' : 'password'"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.signinCustomer.confirmPassword.placeholder"
          persistent-placeholder
        >
        </v-text-field>
      </div>
      <div class="pl-9 pr-9 mb-9 remove-margin-laterals">
        <div class="display-flex align-items-center">
          <v-btn
            @click="$router.push({ name: 'Signin' })"
            elevation="0"
            class="button-cancel mon-regular"
          >
            {{ texts.signinCustomer.buttonCancel }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="signinCustomers"
            elevation="0"
            :loading="bLoading"
            :disabled="!validateForm"
            class="button-login mon-bold"
          >
            {{ texts.signinCustomer.buttonRegister }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SigninCustomerFormLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      itemsCountries: [],
      itemsStates: [],
      name: "",
      lastname: "",
      email: "",
      phone: "",
      enterpriseName: "",
      password: "",
      confirmPassword: "",
      userState: "",
      country: "",
      bShowPassword: false,
      bShowConfirmPassword: false,
      bLoading: false,
    };
  },
  beforeMount() {
    this.getCountries();
  },
  methods: {
    signinCustomers: function () {
      const self = this;
      this.bLoading = true;

      if (
        this.password !== this.confirmPassword ||
        this.confirmPassword !== this.password
      ) {
        this.bLoading = false;
        this.mixError(self.texts.signinCustomer.messageErrorPassword);
      } else {
        const payload = {
          oEnterprise: {
            sName: this.enterpriseName,
            sLocationStateId: this.userState,
          },
          oCustomer: {
            sName: this.name,
            sLastname: this.lastname,
            sEmail: this.email,
            sPhoneNumber: this.phone,
            sPassword: this.confirmPassword,
          },
        };
        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/customers/enterprises`,
          payload
        )
          .then((response) => {
            this.bLoading = false;

            this.mixSuccess(response.data.message);
            setTimeout(function () {
              self.$router.push("/home");
            }, 1000);
          })
          .catch((error) => {
            this.bLoading = false;

            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    getCountries: function () {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.itemsCountries = response.data.results.filter((e)=> e.sCountryId !== '236ce2ad-d8d8-41eb-8451-a0c7c6a7434b');
          // Estados unidos
          this.country = response.data.results[0].sCountryId;
          this.getStates();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selecState: function () {
      this.state = "";
      this.getStates();
    },
    getStates: function () {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.country}/states`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          this.itemsStates = response.data.results;
          // this.state = response.data.results[0].sStateId;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    home: function () {
      this.$router
        .push({
          name: "Home",
        })
        .catch(() => {});
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.name !== "" &&
        this.lastname !== "" &&
        this.email !== "" &&
        this.country !== "" &&
        this.phone !== "" &&
        this.enterpriseName !== "" &&
        this.userState !== "" &&
        this.password !== "" &&
        this.confirmPassword !== ""
      );
    },
  },
};
</script>

<style scoped>
.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

.button-create-account {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  background-color: #ffffff !important;
  text-transform: initial;
  font-size: 14px;
  width: 100%;
}

.link-forgot-password {
  text-transform: initial;
  color: #0049b7;
  text-decoration: underline;
  letter-spacing: 0px;
}

.button-login {
  width: 47%;
  height: 40px !important;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 13px;
  text-transform: initial;
}

.button-login:disabled {
  opacity: 0.5;
  cursor: no-drop !important;
}

.button-login:disabled::before {
  pointer-events: initial;
}

.button-cancel {
  width: 47%;
  height: 40px !important;
  background-color: transparent !important;
  opacity: 1;
  font-size: 13px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  color: #283c4d;
  border-radius: 10px;
  text-transform: initial;
}

.text-title {
  font-size: 30px;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
}

.container-form-login {
  width: 100%;
  /* height: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding-top: 100px; */
  /* background-color: khaki; */
}

.center-horizontall {
  display: flex;
  justify-content: center;
}

.content-image {
  width: 260px;
  height: 45px;
}

.content-image:hover {
  cursor: pointer;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

/* .content-login {
  width: 70%;
  margin-top: -13%;
} */

@media (max-width: 720px) {
  .content-login {
    width: 100%;
  }
  .text-title {
    font-size: 20px;
  }
  .remove-pa-9 {
    padding: 15px !important;
  }
  .remove-margin-laterals {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
</style>